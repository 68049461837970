@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

table thead th.position {
  @apply sticky top-0 z-10 border-b border-gray-300 bg-white p-3 text-right;
}
table thead th.position:first-child {
  @apply sticky left-0 z-20 border-b border-r border-gray-300 text-left;
}
table tbody th.position {
  @apply sticky left-0 z-10 border-r border-b border-gray-300 bg-white text-left;
}

table thead tr:last-child th.position {
  @apply border-b;
}

table thead tr:last-child th:last-child.position {
  @apply border-b border-r-0;
}

table td {
  @apply border-b border-gray-300 bg-white px-4 py-5 text-center;
}

table tr:last-child th {
  @apply border-b-0;
}
table tr:last-child td {
  @apply border-b-0;
}

@layer components {
  .position {
    @apply bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell;
  }
  .position-cell {
    @apply px-3 py-3.5 text-right text-sm text-gray-900 sm:pl-6 lg:pl-8;
  }
}
